<template>
  <div class="landingPage">
    <div v-if="user" class="row">
      <div class="col-12">
        <div class="card rounded-lg">
          <div class="card-body">
            <ValidationObserver
                v-slot="{ handleSubmit, invalid, touched }"
                ref="letterForm"
            >
              <form
                  class="letter-form d-flex flex-column"
                  @submit.prevent="handleSubmit(saveSearch)"
              >
                <div v-if="apiLoaded" class="row">
                  <div class="col-lg-3">
                    <div class="row">
                      <div class="col"><TextInput
                          v-model="searchForm.keyword"
                          name="keyword"
                          rules="required"
                          label="Choose a Keyword"
                      /></div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <label>Choose a Template</label>
                        <SelectInput v-model="searchForm.letter_id" name="Template Page" rules="required" :options="letters" />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="row">
                      <div class="col mb-3">
                        <label>Choose a City</label>
                        <vue-google-autocomplete id="map" required :fields="['address_components','geometry','formatted_address']" classname="form-control" types="(cities)" placeholder="Start typing" @placechanged="setCityData">
                        </vue-google-autocomplete>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <label>Choose a Landing Page</label>
                        <SelectInput v-model="searchForm.page_id" name="Landing Page" rules="required" :options="pages" />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <label><span style="visibility: hidden">Search</span></label>
                    <b-button type="submit" :disabled="searhing || searchForm.lat === '' || (invalid && touched)" variant="success" class="px-5 d-block">
                      <b-spinner v-if="searhing" small />
                      <span v-else> Search For Leads</span>
                    </b-button>
                  </div>
            </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
        <div v-if="total > 0" class="card rounded-lg">
          <div class="card-body">
            <div class="font-weight-bold mb-3">Local Lead Search</div>
            <div class="tablerounededCorner">
            <table class="rounded-3 table table-striped roundedTable">
              <thead>
              <tr>
                <th>Date</th>
                <th>Keyword</th>
                <th>City</th>
                <th>Letter Template</th>
                <th>Landing Page</th>
                <th>Leads</th>
                <th style="width: 200px;"></th>
              </tr>
              </thead>
              <tbody>
                <tr v-for="search in searches" :key="search.id">
                  <td>{{search.front_date}}</td>
                  <td>{{search.keyword}}</td>
                  <td>{{search.city}}</td>
                  <td>
                    {{search.letter.name}}
                  </td>
                  <td>
                    {{search.page.name}}
                  </td>
                  <td>
                    <b-spinner v-if="!search.complete && !search.stats.leads_sum" small />
                    <div v-else>
                      {{search.stats.leads_sum}}
                    </div>
                  </td>
                  <td style="width: 200px;" class="text-center">
                    <b-spinner v-if="!search.complete && !search.stats.leads_sum" small />
                    <div v-else>
                      <router-link :to="{ name: 'agency.campaign-leads', params: { id: search.id } }" class="btn btn-sm btn-success">View Leads</router-link>
                      <b-button size="sm" variant="primary" class="ml-2" @click="printLetter(search)">Print Letters</b-button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            </div>
            <div v-if="total > 0 && total > searches.length" class="text-center">
              <b-button size="sm" variant="primary" class="mt-4" @click="getSearches">Show More</b-button>
            </div>
          </div>
          <b-modal v-model="selectedPrint.isPrinting" :title="selectedPrint.keyword + ', ' + selectedPrint.city + ' Letters'" :hide-footer="true" size="lg" @close="closePrint">
            <div class="text-center">
              <b-spinner v-if="pdfLoading" />
            </div>
            <embed ref="pdfEmbed" :src="pdfURL(selectedPrint.id)" width="100%" style="min-height: 75vh" @load="pdfLoading = false" />
            <b-button v-if="!pdfLoading" class="w-100 d-block mt-2" @click="downloadPDF">Download Letters</b-button>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueGoogleAutocomplete from "vue-google-autocomplete";
import axios from "axios";
import VueScript2 from "vue-script2";
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
export default {
  components:{
    VueGoogleAutocomplete
  },
  data() {
    return {
      user: {
        agency: {
          whitelabel: {},
          landing_page: {}
        },
      },
      page:1,
      total:0,
      searhing:false,
      pdfLoading:false,
      selectedPrint: {
        isPrinting:false,
        keyword:'',
        city:'',
        id:null
      },
      printLetters:false,
      searchForm:{
        keyword:null,
        lat:null,
        lng:null,
        city:null,
        agency_id:null,
        letter_id:null,
        page_id:null
      },
      apiLoaded:false,
      searches:[],
      letters:[],
      pages:[]
    }
  },
  computed: {

  },
  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user'])
    this.searchForm.agency_id = this.user.agency.id;
    axios.post('/agency-data', {agency_id:this.user.agency.id})
        .then((res) => {
          VueScript2.load(`https://maps.googleapis.com/maps/api/js?key=${res.data.data.google_api_key}&libraries=places`).then(() => {
            this.apiLoaded = true;
          })
        });
    this.getSearches();

    const pusherHost = (process.env.NODE_ENV && process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : process.env.VUE_APP_BASE_API_LOCAL);
    window.Pusher = Pusher;
    // window.Pusher.logToConsole = true;
    window.Echo = new Echo({
      broadcaster: 'pusher',
      key: '295948766410685f3c58',
      cluster: 'us3',
      forceTLS: true,
      authEndpoint: pusherHost+'api/broadcasting/auth',
      auth: {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        },
      },
    });
    window.Echo.private("user." + this.user.id)
        .listen(".LeadMachineSearchComplete", (e) => {
          console.log(e)
          const searchToUpdate = this.searches.find(search => search.id === e.search_id);

          if (searchToUpdate) {
            searchToUpdate.complete = true;
            searchToUpdate.stats.leads_sum = e.leads_sum;
          }
        })
    ;


    /* var pusher = new Pusher('295948766410685f3c58', {
      cluster: 'us3'
    });

    var channel = pusher.subscribe('my-channel');
    channel.bind('my-event', function(data) {
      app.messages.push(JSON.stringify(data));
    }); */
  },
  methods: {
    closePrint(){
      const index = this.searches.findIndex(item => item.id === this.selectedPrint.id);
      if (index !== -1) {
        this.total -= 1;
        this.searches.splice(index, 1);
      }
      this.selectedPrint.isPrinting = false;
    },
    downloadPDF(){
      const pdfURL = this.$refs.pdfEmbed.getAttribute('src');

      const link = document.createElement('a');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.setAttribute('href', pdfURL + '?download');
      link.click();
      document.body.removeChild(link);
    },
    printLetter(letter){
      this.pdfLoading = true;
      this.selectedPrint = letter;
      this.selectedPrint.isPrinting = true;
    },
    pdfURL(letterId){
      return (process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : process.env.VUE_APP_BASE_API_LOCAL) + 'api/v1/agencies/print-letters/' + letterId + '/letters.pdf';
    },
    getSearches(){
      this.$store
          .dispatch('agency/getSearches',{agency_id:this.user.agency.id,page:this.page})
          .then((resp) => {
            this.total = resp.data.total;
            this.searches = this.searches.concat(resp.data.data);
            this.letters = resp.letters;
            this.pages = resp.pages;
            // resp.data.forEach(search => this.searches.push(search));
            this.page += 1;
          })
          .catch((err) => {
            console.log(err);
          })
    },
    setCityData(addressData, placeResultData, id){
      this.searchForm.lat = addressData.latitude;
      this.searchForm.lng = addressData.longitude;
      this.searchForm.city = placeResultData.formatted_address;
    },
    saveSearch(){
      this.searhing = true;
      this.$store
          .dispatch('agency/searchLeads', this.searchForm)
          .then((resp) => {
            this.searches = resp.data;
            this.searhing = false;
            this.total = resp.total;
          })
          .catch((err) => {
            if (err.response.data.errors) {
              // this.$refs.updateLandingForm.setErrors(err.response.data.errors)
            }
            this.searhing = false;
          })
    }
  },
}
</script>
<style lang="scss">
.tablerounededCorner {
  border: 1px solid #ccc;
  border-radius: 10px;
}
.roundedTable thead tr:last-child th{
  border-bottom: 1px solid #ccc;
}
.roundedTable {
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  margin: 0;
}

.roundedTable th{
  padding: .6em;
}

.roundedTable td {
  padding: .4em;
}

.roundedTable th {
  text-align: left;
}

.roundedTable tr:last-child td {
  border-bottom: none;
}
.roundedTable.table-striped tbody tr:nth-of-type(odd) {
  background-color: #eceff6;
}

</style>
